<script>
export default {
  methods: {
    submitForm: function (e) {
      this.$emit("submit");

      e.preventDefault();
    },
  },
};
</script>

<template>
  <form class="main-form" @submit="submitForm">
    <div class="main-form__wrapper">
      <slot></slot>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.main-form {
}
</style>
