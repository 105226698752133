<script>
import BalanceOutputItem from "@/components/balance/balance-status-output/BalanceOutputItem.vue";
import { DateTime } from "luxon";
import formatMoney from "@/package/helpers/format-money";

export default {
  methods: { formatMoney },
  components: { BalanceOutputItem },
  props: {
    group: {
      type: Array,
      default: () => [],
    },

    dateGroup: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isVisible: false,
    };
  },

  computed: {
    formattedDate() {
      // const currentDate = DateTime.local();
      // const date = DateTime.fromFormat(this.dateGroup, "dd.MM.yyyy");
      // return currentDate.setLocale("ru").toFormat("d MMMM");
      // return currentDate.setLocale('ru').toFormat("d MMMM, yyyy'г'");;
      const date = DateTime.fromFormat(this.dateGroup, "dd.MM.yyyy").setLocale(
        "ru"
      );
      const currentYear = DateTime.now().year;
      const baseDate = `${date.day} ${date.toFormat("MMMM")}`;

      // Проверяем, является ли год текущим
      if (date.year === currentYear) {
        return baseDate;
      } else {
        return `${baseDate}, ${date.year}`;
      }
    },
  },

  mounted() {
    this.isVisible = true;
  },
};
</script>

<template>
  <div class="balance-output-list">
    <!--    withdraw_hold - Заявка на вывод средств
    withdraw_hold_revert - Возврат вывода средств
    withdraw_from_hold - Вывод средств
-->
    <transition name="fade">
      <h4 v-if="isVisible" class="balance-output-list__date">
        {{ formattedDate }}
      </h4>
    </transition>

    <transition-group name="fade">
      <div
        v-for="item in group"
        :key="item.check_id"
        class="balance-output-list__item"
      >
        <BalanceOutputItem
          :status="item.check_type"
          :time="item.created_at"
          :amount="formatMoney(item.amount)"
        />

        <div class="balance-output-list__separator" />
      </div>
    </transition-group>

    <!--    <BalanceOutputItem-->
    <!--      status="withdrawals_refund"-->
    <!--      :time="new Date()"-->
    <!--      amount="23000"-->
    <!--    />-->

    <!--    <div class="balance-output-list__separator" />-->

    <!--    <BalanceOutputItem-->
    <!--      status="withdrawal_funds"-->
    <!--      :time="new Date()"-->
    <!--      amount="2000"-->
    <!--    />-->

    <!--    <div class="balance-output-list__separator" />-->
  </div>
</template>

<style lang="scss" scoped>
.balance-output-list {
  &__date {
    @include body-1-bold;

    margin-bottom: 16px;
  }

  &__separator {
    width: auto;
    height: 1px;
    background: $dark-primary;
    opacity: 0.1;
    margin: 16px 0 16px 48px;

    @media (max-width: 576px) {
      margin: 16px 0 16px 44px;
    }

    //&:last-child {
    //  display: none;
    //}
  }

  &:last-child {
    .balance-output-list__item:last-child > .balance-output-list__separator {
      display: none;
    }
  }
}
</style>
