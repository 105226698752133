<script>
export default {
  components: {},
  props: {
    text: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: "",
    },
  },

  computed: {},
};
</script>

<template>
  <div class="balance-output-empty">
    <span v-if="icon" :class="icon" class="balance-output-empty__icon"></span>

    <p class="balance-output-empty__text">{{ text }}</p>
  </div>
</template>

<style lang="scss" scoped>
.balance-output-empty {
  width: 100%;
  min-height: 124px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: $background-grey;

  &__icon {
    width: 32px;
    height: 32px;
    background: $dark-third;
  }

  &__text {
    @include text-2;

    color: $dark-third;
  }
}
</style>
