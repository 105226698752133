<script>
import { mapActions } from "vuex";
import BalanceOutputNoticeWithoutCard from "@/components/balance/BalanceOutputNoticeWithoutCard.vue";
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";
import MainDocument from "@/components/helpers/MainDocument";

export default {
  components: {
    MainDocument,
    BalanceOutputNoticeWithoutCard,
    ButtonRedirect,
  },

  data() {
    return {
      documents: [],
    };
  },

  methods: {
    ...mapActions({
      loadDocuments: "loadDocuments",
    }),
  },

  mounted() {
    this.loadDocuments({ page: "withdraw" }).then((response) => {
      this.documents = response.data.withdraw;
    });
  },
};
</script>

<template>
  <div class="output-without-card">
    <balance-output-notice-without-card />

    <div class="output-without-card__redirect-buttons">
      <button-redirect
        text="Регистрация на сервисе партнёра Консоль"
        arrow-position="right"
        @click="$router.push({ name: 'SelfEmployed' })"
      />
    </div>

    <div class="output-without-card__manual manual">
      <p class="manual__title">Как оформить самозанятость или ИП?</p>

      <main-document
        v-for="document in documents"
        :key="document.id"
        :title="document.original_name"
        :description="document.description"
        :url="document.url"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.output-without-card {
  &__redirect-buttons {
    margin: $space-xxl 0;

    .button-redirect {
      &:not(:last-child) {
        margin-bottom: $space-m;
      }
    }
  }

  &__manual {
    background-color: $background-grey;
    border-radius: $space-s;
    padding: $space-xl;
    max-width: 694px;
  }

  .manual {
    &__title {
      @include body-1;
      color: $dark-primary;
      margin-bottom: $space-l;
    }
  }
}
</style>
