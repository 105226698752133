<script>
import BalanceOutputNoticeWithCard from "@/components/balance/BalanceOutputNoticeWithCard.vue";
import MainTabs from "@/components/helpers/MainTabs.vue";
import BalanceOutputCardForm from "@/components/balance/BalanceOutputCardForm.vue";
import BalanceOutputIndividualEntrepreneur from "@/components/balance/BalanceOutputIndividualEntrepreneur.vue";
import MainNotice from "@/components/helpers/MainNotice.vue";

export default {
  components: {
    BalanceOutputNoticeWithCard,
    MainTabs,
    BalanceOutputCardForm,
    BalanceOutputIndividualEntrepreneur,
    MainNotice,
  },

  data() {
    return {
      selectedTab: {
        id: "card",
        title: "Банковская карта",
        icon: null,
      },

      tabs: [
        {
          id: "card",
          title: "Банковская карта",
          icon: null,
        },
        {
          id: "individual-entrepreneur",
          title: "ИП",
          icon: null,
        },
      ],

      isShowNotice: false,
      noticeType: "",
      noticeTitle: "",
      noticeDescription: "",
    };
  },

  computed: {
    showCardForm() {
      return this.selectedTab.id === "card";
    },

    showIndividualEntrepreneur() {
      return this.selectedTab.id === "individual-entrepreneur";
    },
  },

  methods: {
    showNotice(type, title, description) {
      this.noticeType = type;
      this.noticeTitle = title;
      this.noticeDescription = description;
      this.isShowNotice = true;
    },
  },
};
</script>

<template>
  <div class="output-with-card">
    <balance-output-notice-with-card @showNotice="showNotice" />

    <main-tabs :selected-tab.sync="selectedTab" :tabs="tabs" />

    <div class="output-with-card__wrapper">
      <balance-output-card-form v-if="showCardForm" @showNotice="showNotice" />

      <balance-output-individual-entrepreneur
        v-if="showIndividualEntrepreneur"
      />
    </div>

    <main-notice
      v-if="isShowNotice"
      :title="noticeTitle"
      :description="noticeDescription"
      :notice-type="noticeType"
      @close="isShowNotice = false"
    />
  </div>
</template>

<style lang="scss" scoped>
.output-with-card {
  .main-tabs {
    max-width: 404px;
    margin-top: 80px;
  }

  &__wrapper {
    padding: $space-xxl 0 0;
  }
}
</style>
