<script>
import { mapState, mapMutations, mapActions } from "vuex";
import MainCheckbox from "@/components/helpers/MainCheckbox.vue";

export default {
  components: { MainCheckbox },

  data() {
    return {
      isSelfEmployed: true,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    isDisabledButton() {
      return this.isSelfEmployed === this.user.is_self_employed;
    },

    title() {
      return this.isSelfEmployed
        ? "Вы получаете выплаты в статусе ИП"
        : "Вы получаете выплаты на банковскую карту";
    },

    text() {
      return this.isSelfEmployed
        ? "Ожидайте выплаты средств на расчетный счет вашего ИП"
        : "Ожидайте выплаты средств на указанный номер карты";
    },
  },

  watch: {
    isSelfEmployed(newValue) {
      if (newValue !== this.user.is_self_employed) {
        this.updateSelfEmployedStatus(this.isSelfEmployed);
      }
    },
  },

  methods: {
    ...mapMutations({
      setSelfEmployed: "auth/SET_SELF_EMPLOYED",
    }),

    ...mapActions({
      updateSelfEmployed: "auth/updateSelfEmployed",
    }),

    updateSelfEmployedStatus(value) {
      this.updateSelfEmployed({ isSelfEmployed: value })
        .then(() => {
          this.setSelfEmployed(this.isSelfEmployed);
          this.$emit(
            "showNotice",
            "success",
            "Успешно",
            "Данные успешно сохранены"
          );
        })
        .catch(() => {
          this.$emit(
            "showNotice",
            "success",
            "Ошибка",
            "Произошла ошибка при сохранении данных"
          );
        });
    },
  },

  created() {
    this.isSelfEmployed = this.user.is_self_employed;
  },
};
</script>

<template>
  <div class="self-employed-notice">
    <h4 class="self-employed-notice__title">
      {{ title }}
    </h4>

    <p class="self-employed-notice__text">{{ text }}</p>

    <main-checkbox
      v-model="isSelfEmployed"
      :model-value="isSelfEmployed"
      title="Получать выплаты как самозанятый и/или ИП"
    />
  </div>
</template>

<style lang="scss" scoped>
.self-employed-notice {
  padding: $space-xl;
  background-color: $light-second;
  border-radius: $space-s;
  max-width: 694px;

  &__title {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: $space-s;
  }

  &__text {
    @include body-1;
    color: $dark-fifth;
    margin-bottom: $space-l;
  }
}
</style>
