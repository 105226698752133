<script>
import { mapState, mapActions } from "vuex";
import { format, parse } from "date-fns";
import MainInput from "@/components/helpers/MainInput";
import MainError from "@/components/helpers/MainError";
import MainForm from "@/components/helpers/MainForm";
import MainButton from "@/components/helpers/MainButton";

export default {
  components: {
    MainButton,
    MainForm,
    MainError,
    MainInput,
  },

  data() {
    return {
      form: {
        cardNumber: "",
        lastName: "",
        firstName: "",
        middleName: "",
        birthday: "",
      },

      updateDelay: false,
    };
  },

  computed: {
    ...mapState({
      cardInfo: (state) => state.balance.cardInfo,
    }),

    isDisabledButton() {
      return (
        !(
          this.form.cardNumber &&
          this.form.lastName &&
          this.form.firstName &&
          this.form.middleName &&
          this.form.birthday
        ) ||
        this.updateDelay ||
        (this.form.cardNumber === this.cardInfo.number &&
          this.form.lastName === this.cardInfo.last_name &&
          this.form.firstName === this.cardInfo.first_name &&
          this.form.middleName === this.cardInfo.middle_name &&
          this.form.birthday === this.cardInfo.birthday)
      );
    },
  },

  methods: {
    ...mapActions({
      updateCardNumber: "balance/updateCardNumber",
      loadCardInfo: "balance/loadCardInfo",
    }),

    submitForm() {
      this.updateCardNumber(this.form)
        .then(() => {
          this.updateDelay = true;
          this.$emit("showNotice", "success", "Новые данные сохранены");

          setTimeout(() => {
            this.updateDelay = false;
          }, 4000);
        })
        .catch(() => {
          this.$emit(
            "showNotice",
            "success",
            "Ошибка",
            "Произошла ошибка при сохранении данных"
          );
        });
    },
  },

  created() {
    this.loadCardInfo().then(() => {
      if (Object.keys(this.cardInfo).length !== 0) {
        this.form.cardNumber = this.cardInfo.number;
        this.form.lastName = this.cardInfo.last_name;
        this.form.firstName = this.cardInfo.first_name;
        this.form.middleName = this.cardInfo.middle_name;
        this.form.birthday = format(
          parse(this.cardInfo.birthday, "yyyy-dd-MM", new Date()),
          "dd.MM.yyyy"
        );
      }
    });
  },
};
</script>

<template>
  <main-form class="balance-output-card-form" @submit="submitForm">
    <main-input
      v-model="form.cardNumber"
      label="Номер карты"
      :mask="'#### #### #### ####'"
    />

    <main-error
      error-message="За последствия некорректно введенных данных, а также за данные неактивных банковских карт, компания ответственности не несет!"
    />

    <main-input v-model="form.lastName" label="Фамилия" />

    <main-input v-model="form.firstName" label="Имя" />

    <main-input v-model="form.middleName" label="Отечство" />

    <main-input
      v-model="form.birthday"
      label="Дата рождения"
      mask="##.##.####"
      placeholder="01.01.1999"
    />

    <main-button
      color="gold"
      title="Отправить"
      type="submit"
      :disabled="isDisabledButton"
    />
  </main-form>
</template>

<style lang="scss" scoped>
.balance-output-card-form {
  max-width: 388px;

  .main-input:first-child {
    margin-bottom: $space-s;
  }

  .main-input:last-of-type {
    margin-bottom: $space-xl;
  }

  .main-error {
    margin-bottom: $space-l;
  }
}
</style>
