<script>
export default {};
</script>

<template>
  <div class="balance-output-individual-entrepreneur">
    <p>
      Если вы уже являетесь индивидуальным предпренимателем и хотите выводить
      средства в данном статусе, то вам необходимо отправить заявку на почту (<a
        href="mailto:mail@email.ru"
      >
        mail@email.ru
      </a>
      ), указав в теме письма "Выплата на ИП".
    </p>

    <p>
      Пока у Вас не подтвержден статус ИП, вы можете получать выплаты денежных
      средств на банковскую карту.
    </p>

    <div class="balance-output-individual-entrepreneur__manual manual">
      <p>Подробная инструкция</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance-output-individual-entrepreneur {
  max-width: 630px;

  p {
    @include body-1;
    color: $dark-fifth;
    margin-bottom: $space-m;
  }

  a {
    text-decoration: none;
    color: $blue;
  }

  &__manual {
    margin-top: $space-xxl;
  }

  .manual {
    p {
      @include body-1;
      color: $dark-primary;
      margin-bottom: $space-l;
    }
  }
}
</style>
