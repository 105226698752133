<script>
import { mapState, mapMutations, mapActions } from "vuex";
import MainCheckbox from "@/components/helpers/MainCheckbox.vue";
import MainInput from "@/components/helpers/MainInput.vue";
import TooltipInfo from "@/components/tooltips/TooltipInfo.vue";

export default {
  components: { TooltipInfo, MainInput, MainCheckbox },

  data() {
    return {
      isSelfEmployed: true,
      isShowTooltip: false,

      inn: "",
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    isDisabledButton() {
      return this.isSelfEmployed === this.user.is_self_employed;
    },

    title() {
      return this.isSelfEmployed
        ? "Ожидайте поступления средств"
        : "Выплаты не производятся";
    },

    text() {
      return this.isSelfEmployed
        ? "Денежные средства поступят по реквизитам, указанным в сервисе Konsol. Если вы не прошли все этапы оформления, вы не сможете получать выплаты средств."
        : "<p>Выплата средств возможна только для самозанятых и/или ИП. Оформите статус по ссылкам ниже и укажите 12-значный ИНН.</p> <br />" +
            "<p>Если вы совершаете вывод денежных средств на близкого родственника, то вам необходимо заполнить заявление «Заявление на вывод средств» и отправить его на почту hello@rc.company</p>";
    },

    isDisabledCheckbox() {
      return this.inn.length < 12;
    },

    isShowInput() {
      return !this.user?.inn || !this.isSelfEmployed;
    },
  },

  watch: {
    isSelfEmployed(newValue) {
      if (newValue !== this.user.is_self_employed) {
        this.setINN(this.inn).then(() => {
          this.updateSelfEmployedStatus(this.isSelfEmployed);
        });
      }
    },

    user: {
      deep: true,
      handler() {
        this.isSelfEmployed = this.user.is_self_employed;
      },
    },
  },

  methods: {
    ...mapMutations({
      setSelfEmployed: "auth/SET_SELF_EMPLOYED",
    }),

    ...mapActions({
      updateSelfEmployed: "auth/updateSelfEmployed",
      setINN: "auth/setINN",
    }),

    updateSelfEmployedStatus(value) {
      this.updateSelfEmployed({ isSelfEmployed: value })
        .then(() => {
          this.setSelfEmployed(value);
        })
        .catch(() => {
          this.isSelfEmployed = !value;
        });
    },

    showTooltip() {
      if (!this.isSelfEmployed) {
        this.isShowTooltip = true;
      }
    },
  },

  created() {
    this.isSelfEmployed = this.user.is_self_employed;
    this.inn = this.user.inn || "";
  },
};
</script>

<template>
  <div class="self-employed-notice">
    <h4 class="self-employed-notice__title">
      {{ title }}
    </h4>

    <div class="self-employed-notice__text" v-html="text"></div>

    <MainInput
      v-if="isShowInput"
      v-model="inn"
      class="self-employed-notice__input"
      :label="'ИНН'"
      :background="'white'"
      :mask="'############'"
    />

    <p v-else class="self-employed-notice__inn">ИНН для выплаты: {{ inn }}</p>

    <div class="self-employed-notice__checkbox-wrapper">
      <MainCheckbox
        v-model="isSelfEmployed"
        :model-value="isSelfEmployed"
        :title="'Я оформлен(а) как самозанятый и/или ИП и хочу выводить денежные средства'"
        :disabled="isDisabledCheckbox"
        @mouseenter.native="showTooltip"
        @mouseleave.native="isShowTooltip = false"
      />

      <TooltipInfo
        v-if="isShowTooltip"
        class="self-employed-notice__tooltip"
        description="Укажите ИНН, чтобы получать выплаты"
        :is-closed="false"
        auto-close
        @close="isShowTooltip = false"
      />
    </div>
  </div>
</template>

<style lang="scss">
.self-employed-notice {
  padding: $space-xl;
  background-color: $light-second;
  border-radius: $space-s;
  max-width: 694px;

  &__title {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: $space-s;
  }

  &__text {
    @include body-1;
    color: $dark-fifth;
    margin-bottom: $space-l;
  }

  &__input {
    max-width: 320px;
  }

  &__inn {
    @include body-1;
    color: $dark-primary;
    margin-bottom: 24px;
  }

  &__tooltip {
    position: absolute;
    left: 0;
    top: calc(100% + 16px);

    .tooltip-info__description {
      @include text-2;
      color: $dark-primary;
    }
  }

  &__checkbox-wrapper {
    position: relative;
  }

  .main-checkbox {
    span {
      max-width: 100% !important;
    }
  }
}
</style>
