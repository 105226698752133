<template>
  <div class="tooltip-info">
    <div
      v-if="isClosed"
      class="tooltip-info__close"
      @click.stop="$emit('close')"
    >
      <img src="../../assets/icons/close_bold.svg" alt="close" />
    </div>

    <div v-if="title" class="tooltip-info__title">
      {{ title }}
    </div>

    <div v-if="description" class="tooltip-info__description">
      {{ description }}
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },

    description: {
      type: String,
    },

    autoClose: {
      type: Boolean,
      default: false,
    },

    isClosed: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    if (this.autoClose) {
      setTimeout(() => {
        this.$emit("close");
      }, 3000);
    }
  },
};
</script>

<style scoped lang="scss">
.tooltip-info {
  width: 320px;
  padding: $space-m;
  background-color: $light-primary;
  border-radius: 8px;
  position: absolute;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 99;

  &__close {
    position: absolute;
    right: $space-m;
    top: $space-m;
    display: flex;
    cursor: pointer;
    height: 16px;
    width: 16px;

    > img {
      width: 100%;
    }
  }

  &__title {
    width: calc(100% - 32px);
    margin-bottom: $space-xs;
    @include text-2-bold;
    color: $dark-primary;
  }

  &__description {
    width: calc(100% - 24px);
    color: $dark-sixth;
    word-wrap: break-word;
    @include caption-1;
  }
}
</style>
