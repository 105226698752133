<script>
export default {
  props: {
    errorMessage: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div class="main-error">
    {{ errorMessage }}
  </div>
</template>

<style lang="scss" scoped>
.main-error {
  padding: $space-m;
  background-color: $light-second;
  border-radius: $space-s;
  color: $red;
  @include text-2;
}
</style>
