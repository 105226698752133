<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BalancePeriodTotal from "@/components/balance/balance-history-operations/BalancePeriodTotal.vue";
import BalanceOutputList from "@/components/balance/balance-status-output/BalanceOutputList.vue";
import BalanceOutputEmpty from "@/components/balance/balance-status-output/BalanceOutputEmpty.vue";
import MainObserver from "@/components/helpers/MainObserver.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";
import formatMoney from "@/package/helpers/format-money";

export default {
  components: {
    BalancePeriodTotal,
    BalanceOutputList,
    BalanceOutputEmpty,
    MainLoader,
    MainObserver,
  },

  data() {
    return {
      metaOperations: {
        limit: 15,
        page: 1,
      },
      firstLoading: true,
    };
  },

  watch: {},

  computed: {
    ...mapState({
      withdrawals: (state) => state.balance.withdrawals,
      withdrawalsAmount: (state) => state.balance.withdrawalsAmount,
      isLoadingWithdrawals: (state) => state.balance.isLoadingWithdrawals,
    }),

    ...mapGetters({
      isLastPageWithdrawals: "balance/isLastPageWithdrawals",
    }),

    isVisibleWithdrawal() {
      return this.withdrawals.length > 0;
    },

    unitRc() {
      return formatMoney(this.withdrawalsAmount);
    },
  },

  methods: {
    ...mapActions({
      getHistoryWithdrawals: "balance/getHistoryWithdrawals",
      getForMountWithdrawals: "balance/getForMountWithdrawals",
      getNextWithdrawals: "balance/getNextWithdrawals",
    }),

    intersectObserver() {
      if (!this.isLoadingWithdrawals) this.getNextWithdrawals();
    },
  },

  mounted() {
    this.getForMountWithdrawals(this.metaOperations).then(() => {
      this.firstLoading = false;
    });
  },
};
</script>

<template>
  <div class="balance-output-history">
    <h3>Выводы средств</h3>

    <template v-if="!firstLoading">
      <template v-if="isVisibleWithdrawal">
        <BalancePeriodTotal
          title="Всего выведено"
          :unit-rc="unitRc"
          :mobile-column="false"
        />

        <div>
          <BalanceOutputList
            v-for="group in withdrawals"
            :key="group.date"
            :group="group.items"
            :date-group="group.date"
          />
        </div>
      </template>

      <BalanceOutputEmpty
        v-else
        icon="icon-history-thin-32"
        text="Вы еще не выводили средства"
      />
    </template>

    <MainObserver v-if="!isLoadingWithdrawals" @intersect="intersectObserver" />

    <transition name="fade">
      <MainLoader v-if="!isLastPageWithdrawals" />
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.balance-output-history {
  display: flex;
  flex-direction: column;
  gap: 32px;

  > h3 {
    @include title-3;

    color: $dark-primary;
  }
}
</style>
