<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },

    time: {
      type: String,
      default: "",
    },

    amount: {
      type: String,
      required: true,
    },
  },

  computed: {
    baseInformation() {
      switch (this.status) {
        case "withdraw_hold":
          return {
            title: "Заявка на вывод средств",
            icon: "icon-sync",
            status: "В обработке",
          };

        case "withdraw_hold_revert":
          return {
            title: "Возврат вывода средств",
            icon: "icon-reply",
            status: "Выплачено",
          };

        case "withdraw_from_hold":
          return {
            title: "Вывод средств",
            icon: "icon-circle-done-2",
            status: "Выплачено",
          };

        default:
          return null;
      }
    },

    formattedTime() {
      return new Date(this.time).toLocaleString("ru", {
        hour: "numeric",
        minute: "numeric",
      });
    },

    formattedRC() {
      return `${this.amount.toLocaleString()} RC`;
    },
  },
};
</script>

<template>
  <div class="balance-output-item">
    <div class="balance-output-item__left">
      <div class="balance-output-item__icon">
        <span :class="baseInformation.icon" />
      </div>
      <div class="balance-output-item__operation operation">
        <p class="operation__name">
          <!--          {{ formattedType }}-->
          {{ baseInformation.title }}
        </p>
        <p class="operation__person">{{ baseInformation.status }}</p>
      </div>
    </div>
    <div class="balance-output-item__right">
      <p class="balance-output-item__amount">{{ formattedRC }}</p>
      <!--      <p v-if="operation.pv" class="balance-output-item__amount">-->
      <!--        {{ formattedPV }}-->
      <!--      </p>-->
      <!--      <p-->
      <!--        v-if="operation.cv_left || operation.cv_right"-->
      <!--        class="balance-output-item__amount"-->
      <!--      >-->
      <!--        {{ formattedCV }}-->
      <!--      </p>-->
      <!--      <p class="balance-output-item__time">{{ formattedTime }} dwdw</p>-->
      <p class="balance-output-item__time">{{ formattedTime }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance-output-item {
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  @media (max-width: 575px) {
    min-height: 41px;
    align-items: flex-start;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 575px) {
      align-items: flex-start;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__icon {
    min-width: 32px;
    width: 32px;
    height: 32px;
    background-color: $dark-primary;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      width: 20px;
      height: 20px;
      background-color: $light-primary;
    }
  }

  .operation {
    &__name {
      @include body-1;
      color: $dark-primary;
    }

    &__person {
      margin-top: 4px;
      @include caption-1;
      color: $dark-fifth;
    }
  }

  &__amount {
    @include body-1;
    color: $dark-primary;
    margin-bottom: 4px;
    white-space: nowrap;
  }

  &__time {
    @include caption-1;
    color: $dark-third;
  }

  &_declined {
    .operation {
      &__name {
        color: $red;
      }
    }

    .balance-output-item {
      &__amount {
        color: $red;
      }
    }
  }
}

@media (max-width: 575px) {
  .balance-output-item {
    //padding: 16px 12px;
  }
}
</style>
