<script>
import { mapState } from "vuex";
import MainTitleBack from "@/components/helpers/MainTitleBack";
import BalanceOutputWithoutCard from "@/components/balance/BalanceOutputWithoutCard.vue";
import BalanceOutputWithCard from "@/components/balance/BalanceOutputWithCard.vue";
import BalanceOutputHistory from "@/components/balance/balance-status-output/BalanceOutputHistory.vue";

const REPRESENTATIONS_IDS_OUTPUT_WITHOUT_CARD = [63, 65, 94];

export default {
  name: "BalanceOutput",

  components: {
    BalanceOutputHistory,
    MainTitleBack,
    BalanceOutputWithoutCard,
    BalanceOutputWithCard,
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    showOutputWithCard() {
      const id = this.user?.representation?.id;

      return REPRESENTATIONS_IDS_OUTPUT_WITHOUT_CARD.includes(id);
    },
  },

  mounted() {
    if (this.user.tariff === null || this.user.tariff.code === "free_c") {
      this.$router.push({ name: "Balance" });
    }
  },
};
</script>

<template>
  <div class="output default-page-wrapper">
    <main-title-back title="Выплата средств" href="Balance" />

    <balance-output-with-card v-if="showOutputWithCard" />

    <balance-output-without-card v-else />

    <BalanceOutputHistory class="output__history" />
  </div>
</template>

<style scoped lang="scss">
.output {
  background-color: $background-white;
  padding-top: $space-xxl;
  padding-bottom: $space-xxl;

  &__history {
    margin-top: 64px;
    max-width: 694px;

    @media (max-width: 1023px) {
      max-width: none;
    }
  }
}
</style>
